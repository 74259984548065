import { createTheme } from '@mui/material/styles';

export const PRIMARY_COLOR = '#FF393F';

export const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_COLOR,
    },
    secondary: {
      main: '#FF9EA1',
    },
  },
  //"PT Sans",helvetica,arial,sans-serif
  typography: {
    fontFamily: [
      '"PT Serif"',
      '"PT Sans"',
      'helvetica',
      'arial',
      'sans-serif',
    ].join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          height: 38,
          '&.Mui-disabled': {
            background: '#FF9EA1',
            color: '#fff',
          },
          '&': {
            border: '1px solid #CCCCCC',
            color: '#999999',
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          border: '1px solid #9CA5AC',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#9CA5AC',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderColor: '#9CA5AC',
        },
      },
    },
  },
});

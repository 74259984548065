import React, { useEffect } from 'react';
import { CardSection } from '../../shared/components/card/CardSection';
import { Grid, Typography } from '@mui/material';
import { NewsletterOption } from './components/NewsletterOption';
import { MainLoader } from '../../shared/components/MainLoader';
import {
  MailchimpSubscription,
  useMailchimpAudiencesLazyQuery,
} from '../../shared/types/generated';
import { useAuth } from '../auth/auth-hook';
import { getMailchimpAudienceStatus } from './newsletter-utils';

export const Newsletters: React.FC = () => {
  const { user } = useAuth();
  const [callAction, { data, loading }] = useMailchimpAudiencesLazyQuery();

  useEffect(() => {
    if (user) {
      callAction({
        fetchPolicy: 'network-only',
        variables: {
          where: {
            email: user.email,
          },
        },
      });
    }
  }, [user, callAction]);


  const mailchimpAudiences = data?.mailchimpAudiences?.items;
  const mailchimpUserSubscriptions = data?.mailchimpSubscriptions?.subscriptions;

  return (

    <CardSection
      title={
        <Typography variant="h6" fontWeight={800}>
          Newsletters
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">
          Obtén lo mejor de Animal Político en tu bandeja de entrada, selecciona
          los Newsletters que te gustaría recibir.
        </Typography>
      }
    >
      <Grid container p={2} spacing={2}>
        {loading ? (
          <Grid item xs={12}>
            <MainLoader height="300px">Cargando newsletter...</MainLoader>
          </Grid>
        ) : (
          mailchimpAudiences?.map((item, i) => (
            <Grid
              key={'news' + i}
              minHeight={180}
              height={'100%'}
              item
              xs={12}
              sm={6}
              lg={6}
            >
              <NewsletterOption
                name={item?.name as string}
                description={item?.description as string}
                isSubscribed={item?.is_subscribed as boolean}
                audience={item?.audience as string}
                isSelected={!!getMailchimpAudienceStatus(mailchimpUserSubscriptions as MailchimpSubscription[], item?.audience as string)}
                onSuccess={() => {
                  callAction({
                    fetchPolicy: 'network-only',
                    variables: {
                      where: {
                        email: user?.email as string,
                      },
                    },
                  });
                }}
              />
            </Grid>
          ))
        )}
      </Grid>
    </CardSection>
  );
};

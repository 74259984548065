import React from 'react';
import { createStyles, makeStyles } from '@mui/styles';
import { Theme } from '@mui/material';
import { HeaderAuth } from './HeaderAuth';
import { FooterAuth } from './FooterAuth';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    root: {
      background: '#F3F4F6 !important',
      flexGrow: 1,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
  }),
);

type LayoutProps = {
  children: React.ReactNode;
  isInvited?: boolean;
  withLogos?: boolean;
};
export const LayoutAuth: React.FC<LayoutProps> = ({
  children,
  isInvited,
  withLogos,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <HeaderAuth isInvited={isInvited} />
      <div className={classes.root}>{children}</div>
      <FooterAuth withLogos={withLogos} />
    </div>
  );
};

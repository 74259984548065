import { StripeCoupon } from '../../shared/types/generated';


export const couponDiscountAmount = (coupon: StripeCoupon | null
  , price = 0): number => {

  let discount = 0;


  if (coupon?.discount_percent) {
    discount = (coupon?.discount_percent / 100) * price;

    return discount > 0 ? discount : 0;
  } else if (coupon?.discount_amount) {

    discount = price - coupon?.discount_amount;

    return discount > 0 ? discount : 0;
  }


  return discount;
};
import { createEvent } from '@cobuildlab/react-simple-state';
import { EasyDataAuthenticate, EasyDataCreate } from './easy-data-types';
import { universalCookies } from './shared/cookies';
import { EASY_DATA_UUID_COOKIE } from './shared/easy-data-contants';

export const easyDataAuthenticateEvent = createEvent<EasyDataAuthenticate>({
  initialValue: {
    uuid: universalCookies.get(EASY_DATA_UUID_COOKIE) || '',
  },
});

export const easyDataAuthenticateErrorEvent = createEvent();

export const easyDataCreateEvent = createEvent<{
  analyticEvent: EasyDataCreate | undefined;
}>({
  initialValue: {
    analyticEvent: undefined,
  },
});
export const easyDataCreateEventEvent = createEvent();

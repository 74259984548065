import React from 'react';
import { Box, Theme, Typography } from '@mui/material';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { useNavigate } from 'react-router-dom';
import { createStyles, makeStyles } from '@mui/styles';
import { Header } from '../../shared/components/layout/user/Header';
import { UserChangeEmailMessage } from './user-types';
import { changeUserEmailText } from './user-utils';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    back: {
      width: 180,
    },
    container: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
    },
    root: {
      flexGrow: 1,
      display: 'flex',
      marginTop: '100px',
      alignItems: 'center',
      justifyContent: 'center',
    },
    body: {},
  }),
);

export const UserChangeEmail: React.FC = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search);
  const message = queryParameters.get('message');
  const text = changeUserEmailText(message as UserChangeEmailMessage);

  return (
    <Box className={classes.container}>
      <Header />
      <main className={classes.root}>
        <Box textAlign="center">
          <Typography variant="h5" fontWeight={800} mb={1}>
            {text}
          </Typography>
          <ButtonDefault className={classes.back} onClick={() => navigate('/')}>
            IR A PERFIL
          </ButtonDefault>
        </Box>
      </main>
    </Box>
  );
};

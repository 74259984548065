import React from 'react';
import {
  CircularProgress,
  Box,
  ButtonProps,
  Button as MUIButton,
} from '@mui/material';

interface ButtonDefaultProps extends ButtonProps {
  isLoading?: boolean | undefined;
}

const Loading: React.FC = () => (
  <Box marginRight={2} display="flex" alignItems="center">
    <CircularProgress size={18} />
  </Box>
);

export const ButtonDefault: React.FC<
  ButtonDefaultProps & { component?: React.ElementType }
> = ({
  children,
  component,
  size = 'large',
  variant = 'contained',
  isLoading,
  disabled,
  ...rest
}) => {
  return (
    <MUIButton
      disabled={isLoading || disabled}
      disableElevation
      variant={variant}
      component={component || 'button'}
      color="primary"
      {...rest}
      size={size}
      style={{ height: 38, color: 'white'}}
    >
      {isLoading ? <Loading /> : children}
    </MUIButton>
  );
};

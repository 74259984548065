import { createAction } from '@cobuildlab/react-simple-state';
import {
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
} from './stripe-events';
import { stripe } from '../../shared/stripe/config';
import Stripe from 'stripe';

export const checkoutPlanSubscription = createAction(
  checkoutPlanSubscriptionEvent,
  checkoutPlanSubscriptionEventError,
  async (props: {
    email: string;
    priceId: string;
    stateUrl: string;
    metadata?: Record<string, string>;
    coupon?: string;
  }): Promise<void> => {
    const origin = window.location.origin;
    const url = await window.location.href;

    let discount: Stripe.PromotionCode | null = null;
    if (props.coupon) {
      const promotionCodes = await stripe.promotionCodes.list({
        code: props.coupon,
      });

      if (promotionCodes.data.length > 0) {
        discount = promotionCodes.data[0];
      }
    }

    const session = await stripe.checkout.sessions.create({
      mode: 'subscription',
      locale: 'es',
      customer_email: props.email,
      line_items: [
        {
          price: props.priceId,
          quantity: 1,
        },
      ],
      success_url: `${origin}?session_id={CHECKOUT_SESSION_ID}&${props.stateUrl}`,
      cancel_url: `${url}?returningStripe=true`,
      metadata: props.metadata,
      ...(discount && {
        discounts: [
          {
            promotion_code: discount.id,
          },
        ],
      }),
    });

    if (!session.url) {
      throw new Error('Error al crear la sesion de pagos.');
    }

    window.location.href = session.url;

    return;
  },
);

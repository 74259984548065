import React from 'react';
import { Box, Grid } from '@mui/material';

const IMAGES = [
  {
    href: 'https://animalpolitico.com',
    alt: 'logo',
    src: '/animallogo.png',
    sizes: [67, 29],
  },
  {
    href: 'https://criteriohidalgo.com',
    alt: 'logo',
    src: '/criteriologo.png',
    sizes: [75, 23],
  },
  {
    href: 'https://www.animalgourmet.com',
    alt: 'logo',
    src: '/gourmetlogo.png',
    sizes: [90, 29],
  },
  {
    href: 'https://newsweekespanol.com',
    alt: 'logo',
    src: '/newsweeklogo.svg',
    sizes: [67, 27],
  },
];

export const PageAssociated: React.FC = () => {
  return (
    <Grid container>
      {IMAGES.map((image) => (
        <Grid item xs={3} textAlign="center">
          <Box>
            <a href={image.href} target="_blank">
              <img
                src={image.src}
                alt={image.alt}
                width={'100%'}
                height={'30px'}
                style={{ objectFit: 'contain' }}
              />
            </a>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

export const ButtonWPP: React.FC = () => {
  const [textAppear, setTextApper] = useState(false);

  useEffect(() => {
    let timeoutOne: any = undefined;
    let timeoutTwo: any = undefined;
    const open = (): void => {
      setTextApper(true);
      timeoutTwo = setTimeout(() => {
        setTextApper(false);
        if (timeoutOne) {
          clearTimeout(timeoutOne);
        }
        if (timeoutTwo) {
          clearTimeout(timeoutTwo);
        }
      }, 15000);
    };
    timeoutOne = setTimeout(open, 5000);
  }, []);
  return (
    <Box
      position={'fixed'}
      bottom={'50px'}
      right={'10px'}
      sx={{ zIndex: 1000000 }}
    >
      <Box
        component={'a'}
        sx={{ cursor: 'pointer', textDecoration: 'none' }}
        href="https://wa.me/525610190580"
        target="_blank"
      >
        <Box display={'flex'} alignContent={'center'} alignItems={'center'}>
          {textAppear && (
            <Box display={'flex'} alignItems={'center'} paddingRight={'5px'}>
              <Box
                padding={'12px'}
                sx={{ background: 'black' }}
                borderRadius={'3px'}
              >
                <Typography
                  fontSize={13}
                  lineHeight={'18px'}
                  color={'white'}
                  sx={{ textDecoration: 'none' }}
                >
                  ¿Tienes alguna duda? <br />
                  Contáctanos {':)'}
                </Typography>
              </Box>
              <Box
                width={0}
                height={0}
                borderTop={'10px solid transparent'}
                borderBottom={'10px solid transparent'}
                borderLeft={'10px solid black'}
              ></Box>
            </Box>
          )}
          <Box width={'60px'} height={'60px'}>
            <img
              src="logo-wpp.png"
              alt="logo whatsapp animal político"
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

import { Grid, TextField, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React, { ChangeEvent } from 'react';
import { SubscriptionBillingInput } from '../../../shared/types/generated';


const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    postalSeparation: {
      padding: '0px 0px 0px 10px !important',
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        padding: '0px 0px 0px 0px !important',
        width: '100%',
      },
    },
  }),
);

type ShippingInfoProps = {
  subscriptionBilling: SubscriptionBillingInput
  onChange: (name: string, value: string) => void;
}
export const ShippingInfo: React.FC<ShippingInfoProps> = (
  {
    subscriptionBilling,
    onChange,

  }) => {
  const classes = useStyles();

  const onChangeHandler = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {

    onChange(event.target.name as keyof SubscriptionBillingInput, event.target.value as string);

  };


  return (
    <Grid container>
      <Grid item xs={12}>
        <TextField
          placeholder={'Calle y numero'}
          name="address"
          fullWidth
          value={subscriptionBilling.address}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
        <TextField
          style={{ marginTop: '15px' }}
          placeholder={'Colonia'}
          name="address1"
          value={subscriptionBilling.address1}

          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
        <TextField
          style={{ marginTop: '15px' }}
          placeholder={'Ciudad'}
          name="city"
          value={subscriptionBilling.city}
          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
        <TextField
          style={{ marginTop: '15px' }}
          placeholder={'Estado'}
          name="state"
          value={subscriptionBilling.state}

          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid xs={12} md={7}>
        <TextField
          style={{ marginTop: '15px' }}
          placeholder={'Pais'}
          name="country"
          value={subscriptionBilling.country}

          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid xs={12} md={5} style={{ marginTop: '15px' }}>
        <TextField
          placeholder={'C.P'}
          value={subscriptionBilling.postal_code}
          name="postal_code"
          className={classes.postalSeparation}
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
      <Grid xs={12}>
        <TextField
          style={{ marginTop: '15px' }}
          placeholder={'Numero de Contacto'}
          name="phone"
          value={subscriptionBilling.phone}

          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
        <TextField
          style={{ marginTop: '15px' }}
          placeholder={'Numero de Contacto (opcional)'}
          name="phone1"
          value={subscriptionBilling.phone1}

          fullWidth
          InputLabelProps={{
            shrink: true,
            style: { fontSize: 19 },
          }}
          size="small"
          variant="outlined"
          onChange={(event) => onChangeHandler(event)}
        />
      </Grid>
    </Grid>
  );
};

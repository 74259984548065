import { UserChangeEmailMessage } from './user-types';

export const changeUserEmailText = (
  message: UserChangeEmailMessage,
): string => {
  let text: string;

  switch (message) {
    case UserChangeEmailMessage.userAlreadyExists:
      text =
        'El correo ya se encuentra asignado a otra cuenta dentro de animal pólitico';
      break;
    case UserChangeEmailMessage.tokenNotFound:
      text = 'El token de verificación es incorrecto';
      break;
    case UserChangeEmailMessage.tokenExpired:
      text = 'El correo ha expirado';
      break;
    case UserChangeEmailMessage.success:
      text = 'Se ha actualizado el correo correctamente';
      break;
    default:
      text = 'Tenemos un problema para actualizar tu correo';
      break;
  }

  return text;
};

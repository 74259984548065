import { UserSavedPostRestApi } from '../../shared/types/generated';
import { APP_ENDPOINT } from '../../shared/constans';

const POST_TYPE_1 = ['newsweek', 'animalgourmet'];
export const generateUrlPost = (
  post: UserSavedPostRestApi | null | undefined,
): string => {
  console.log({ post });

  if (POST_TYPE_1.includes(post?.post_type as string)) {
    return post?.post_url as string;
  }

  if (
    post?.post_type === 'animalmx' ||
    post?.post_type === 'notasplumaje' ||
    post?.post_type === 'elecciones-2024' ||
    post?.post_type === 'hablemosde'
  ) {
    return `${APP_ENDPOINT}${post?.post_uri}`;
  }

  if (post?.post_type === 'verificacion-de-hechos') {
    console.log({ post });
    return `${APP_ENDPOINT}/${post?.post_type}/${post?.sub_post_type}/${post?.post_name}`;
  }

  return `${APP_ENDPOINT}/${post?.post_type}/${post?.post_name}`;
};

import { useCallAction, useStore } from '@cobuildlab/react-simple-state';
import { changePlanStore } from '../payment/payment-events';
import { Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { ButtonDefault } from '../../shared/components/ButtonDefault';
import { PRIMARY_COLOR } from '../../shared/css/theme';
import { checkoutPlanSubscription } from './stripe-actions';
import { useAuth } from '../auth/auth-hook';
import { getPostUrl } from '../auth/auth-utils';
import {
  StripeCoupon,
  SubscriptionBillingInput,
} from '../../shared/types/generated';
import { openDialogAlert } from '../alert/alert-actions';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    icon: {
      color: '#999999',
      fontSize: '20px!important',
      paddingRight: '5px',
    },
    input: {
      background: 'white',
      fontSize: '18px',
      height: '48px',
    },
    button: {
      borderRadius: '5px!important',
      backgroundColor: `${PRIMARY_COLOR}!important`,
      border: 'none!important',
      color: 'white!important',
      '&:disabled': {
        backgroundColor: '#FFCCCC!important',
      },
      height: 45,
    },
  }),
);

interface StripeButtonProps {
  subscriptionBilling: SubscriptionBillingInput | null;
  coupon?: StripeCoupon | null;
}

export const StripeButton: React.FC<StripeButtonProps> = ({
  subscriptionBilling,
  coupon,
}) => {
  const classes = useStyles();
  const { user } = useAuth();
  const { plan } = useStore(changePlanStore);

  const [callCheckoot, loading] = useCallAction(checkoutPlanSubscription, {
    onError: (error) => {
      console.error(error);
    },
  });

  const handleCheckout = (): void => {
    if (!plan?.stripe || !user) {
      return;
    }

    const subscriptionBillingChecked =
      subscriptionBilling &&
      Object.keys(subscriptionBilling).find(
        (key) => !subscriptionBilling[key as keyof SubscriptionBillingInput],
      );

    if (plan?.subscription_billing_is_required && subscriptionBillingChecked) {
      openDialogAlert('Los datos de envio son obligatorios para el plan anual');
      return;
    }

    callCheckoot({
      email: user?.email,
      priceId: plan.stripe?.price_id || '',
      stateUrl: `post_url=${getPostUrl()}`,
      metadata: plan.subscription_billing_is_required
        ? {
            subscriptionBilling: JSON.stringify(subscriptionBilling),
          }
        : undefined,
      coupon: coupon?.coupon ?? undefined,
    });
  };

  if (!plan?.stripe) {
    return (
      <Typography variant="subtitle1" fontWeight={800} textAlign="center">
        Stripe no esta configurado para este plan, contacte a su administrador
      </Typography>
    );
  }

  return (
    <ButtonDefault
      variant="contained"
      size="large"
      fullWidth
      isLoading={loading}
      onClick={() => handleCheckout()}
      className={classes.button}
    >
      Suscribirse ahora
    </ButtonDefault>
  );
};

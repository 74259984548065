import React from 'react';
import {
  PaypalStatuses,
  Plan,
  usePlansQuery,
} from '../../shared/types/generated';
import { useNavigate } from 'react-router-dom';
import { Grid } from '@mui/material';
import { PaymentMethodCard } from './components/PaymentMethodCard';
import { BackButton } from '../../shared/components/buttons/BackButton';
import { PaymentMethodCardLoading } from './components/PaymentMethodCardLoader';
import { getPostUrl, getTestMode } from '../auth/auth-utils';

export const PaymentMethod: React.FC = () => {
  const navigate = useNavigate();

  const { data, loading } = usePlansQuery({
    onCompleted(event) {
      console.log(event);
    },
    variables: {
      where: {
        status: PaypalStatuses.Active,
      },
    },
  });

  return (
    <Grid container pb={4} justifyContent="center" alignItems="center">
      <Grid item xs={12} sm={10} mb={1}>
        <BackButton
          onClick={() => {
            if (getTestMode() === 'true')
              return navigate(`/planes-nuevos?post_url=${getPostUrl()}`);
            return navigate(`/planes?post_url=${getPostUrl()}`);
          }}
        />
      </Grid>
      <Grid item xs={12} sm={10}>
        {loading ? (
          <PaymentMethodCardLoading />
        ) : (
          <PaymentMethodCard items={(data?.plans?.items || []) as Plan[]} />
        )}
      </Grid>
    </Grid>
  );
};

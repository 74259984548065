import React, { ChangeEvent, useState } from 'react';
import { Grid, TextField, Box, Typography, Checkbox } from '@mui/material';
import { styled } from '@mui/styles';
import { ButtonDefault } from '../../../shared/components/ButtonDefault';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { authStore } from '../auth-events';
import validator from 'validator';
import { useStore } from '@cobuildlab/react-simple-state';
import { getPostUrl } from '../auth-utils';
import { useCookies } from 'react-cookie';
import { COOKIES_OPTIONS, USER_COOKIE } from '../../../shared/constans';
import { redirectToHome } from '../../../shared/utils';
import { Site, useSignupMutation } from '../../../shared/types/generated';
import { SignupArgs } from '../auth-types';
import { openDialogAlert } from '../../alert/alert-actions';
import { EmailOutlined, Key } from '@mui/icons-material';
import { Person } from '@mui/icons-material';
import { PRIMARY_COLOR } from '../../../shared/css/theme';

const StyledIcon = styled(EmailOutlined)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});
const StyledIconAvatar = styled(Person)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});
const StyledIconKey = styled(Key)({
  color: '#999999',
  fontSize: '20px!important',
  paddingRight: '5px',
});
const StyledButton = styled(ButtonDefault)({
  borderRadius: '6px!important',
  backgroundColor: `${PRIMARY_COLOR}!important`,
  height: '38px !important',
  border: 'none!important',
  color: 'white!important',
  '&:disabled': {
    backgroundColor: '#FF9EA1!important',
  },
  width: 200,
});

const StyledVisibilityIcon = styled(VisibilityIcon)({
  cursor: 'pointer',
  color: '#999999',
  fontSize: '20px!important',
});

export const AuthRegisterForm: React.FC = () => {
  const { email } = useStore(authStore);
  const [, setCookie] = useCookies([USER_COOKIE]);
  const [conditionAccepted, setConditionAccepted] = useState<boolean>(false);
  const [registerData, setRegisterData] = useState<SignupArgs>({
    email: email ?? '',
    name: '',
    password: '',
  });
  const [passwordType, setPasswordType] = useState<string | undefined>(
    undefined,
  );
  const handleSubmit = (): boolean => {
    let checkData = true;
    Object.keys(registerData).forEach((key) => {
      if (!registerData[key as keyof SignupArgs] && checkData)
        checkData = false;
    });

    return checkData;
  };

  const onChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ): void => {
    setRegisterData({
      ...registerData,
      [event.target.name]: event.target.value,
    });
  };

  const [signUp, { loading }] = useSignupMutation({
    onCompleted: ({ signup }) => {
      setCookie(USER_COOKIE, signup?.token, COOKIES_OPTIONS);
      redirectToHome('/');
    },
    onError: (e) => openDialogAlert(e.message),
  });

  const handleVisibility = (): void => {
    if (!passwordType) setPasswordType('text');
    else setPasswordType(undefined);
  };

  return (
    <Grid container spacing={2}>
      <Grid marginTop={1.5} item xs={12}>
        <Typography
          textAlign={'center'}
          fontSize={'24px'}
          fontWeight={'bold'}
          lineHeight={'26px'}
        >
          Regístrate gratis
        </Typography>
      </Grid>
      <Grid marginTop={0.5} item xs={12}>
        <TextField
          variant="outlined"
          value={registerData?.email}
          name="email"
          placeholder={'Dirección de correo electrónico'}
          type="text"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <StyledIcon />,
            style: { fontSize: '18px', background: 'white', height: '48px' },
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          fullWidth
          placeholder={'Nombre de usuario'}
          name="name"
          type="text"
          size="small"
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: <StyledIconAvatar />,
            style: { fontSize: '18px', background: 'white', height: '48px' },
          }}
          value={registerData?.name}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          placeholder={'Contraseña'}
          name="password"
          size="small"
          type={passwordType ?? 'password'}
          value={registerData?.password}
          fullWidth
          variant="outlined"
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            endAdornment: (
              <StyledVisibilityIcon onClick={() => handleVisibility()} />
            ),
            startAdornment: <StyledIconKey />,
            style: { fontSize: '18px', background: 'white', height: '48px' },
          }}
          onChange={(event) => onChange(event)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign={'center'} color={'#818181'} fontSize={14}>
          La contraseña debe tener al menos 8 caracteres, incluyendo mayúsculas,
          minúsculas, números y caracteres especiales.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Box display={'flex'} alignItems={'start'}>
          <Checkbox
            size="small"
            style={{ padding: '2px 0px', marginRight: '5px' }}
            onChange={() => setConditionAccepted(!conditionAccepted)}
          />
          <Typography component={'span'} color={'#414141'}>
            He leído el{' '}
            <a
              style={{ color: '#414141' }}
              href="https://www.animalpolitico.com/aviso-privacidad"
            >
              aviso de privacidad
            </a>{' '}
            y acepto el uso de mis datos personales.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box textAlign="center" my={2}>
          <StyledButton
            variant="contained"
            size="large"
            color="secondary"
            fullWidth
            disabled={
              !handleSubmit() ||
              !validator.isEmail(registerData.email as string) ||
              !conditionAccepted
            }
            onClick={() => {
              signUp({
                variables: {
                  email: registerData.email,
                  name: registerData.name,
                  url: getPostUrl(),
                  password: registerData.password,
                  site: Site.AnimalPolitico,
                },
              });
            }}
            isLoading={loading}
          >
            Siguiente
          </StyledButton>
        </Box>
      </Grid>
    </Grid>
  );
};

import moment from 'moment';

export const EASY_WALL_DATA_ENDPOINT =
  process.env.REACT_APP_EASY_DATA_ENDPOINT || '';

export const EASY_DATA_DOMAIN_SITE = `.${
  process.env.REACT_APP_EASY_DATA_APP_DOMAIN || ''
}`;

export const EASY_DATA_UUID_COOKIE = 'analytics_uuid';

export const EASY_DATA_COOKIE_OPTIONS = {
  expires: moment().add(1, 'day').toDate(),
  path: '/',
  domain: EASY_DATA_DOMAIN_SITE,
};

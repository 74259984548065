import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { PostsPlansPage } from '../../../shared/constans';

export const RedirectsSubscription: React.FC = () => {
  return (
    <Box
      display={'flex'}
      color={'white'}
      justifyContent={'center'}
      fontFamily={'acumin-pro'}>
      <Box width={{ xs: '100%', lg: '1022px' }}>
        <Box display={'flex'} justifyContent={'center'}>
          <Box
            position={'relative'}
            height={{ xs: 120, md: 134 }}
            width={{ xs: 120, md: 160 }}>
            <img
              src={'/pajarito-lapiz.png'}
              alt={'Logo pajaro animal politico'}
              width={'100%'}
              height={'100%'}
              style={{ objectFit: 'contain' }}
            />
          </Box>
        </Box>
        <Box pb={2}>
          <Typography
            paddingBottom={1}
            fontSize={{ xs: 30, md: 45 }}
            lineHeight={{ xs: '30px', md: '50px' }}
            textAlign={'center'}
            fontWeight={'bold'}>
            GRACIAS A TU SUSCRIPCIÓN
          </Typography>
          <Typography
            fontFamily={'acumin-pro'}
            marginBottom={2}
            fontSize={{ xs: 16, md: 25 }}
            textAlign={'center'}
            fontWeight={'lighter'}
            color={'#E0E0E0'}>
            PODEMOS SEGUIR HACIENDO PERIODISMO
          </Typography>
        </Box>
        <Grid
          container
          fontFamily={'acumin-pro'}
          spacing={{ xs: 2, md: 3 }}
          paddingY={5}>
          {PostsPlansPage.map((item, index) => (
            <Grid item xs={6} md={4} key={'post' + index}>
              <a href={item.url} target="_blank" rel="noreferrer noopener">
                <Box
                  marginBottom={{ xs: 1, md: 2 }}
                  overflow={'hidden'}
                  borderRadius={{ xs: '10px', lg: '20px' }}
                  position={'relative'}
                  height={{
                    xs: '172px',
                    sm: '300px',
                    md: '250px',
                    lg: '365px',
                  }}>
                  <img
                    src={item.img}
                    alt={item.title}
                    width={'100%'}
                    height={'100%'}
                    style={{ objectFit: 'cover' }}
                  />
                </Box>
              </a>
              <a
                href={item.url}
                style={{ color: 'white', textDecoration: 'none' }}
                target="_blank"
                rel="noreferrer noopener">
                <Typography fontSize={{ xs: 12, md: 18 }} fontWeight={400}>
                  {item.title}
                </Typography>
              </a>
            </Grid>
          ))}
        </Grid>
        <Box
          display={'flex'}
          marginTop={{ xs: '20px', md: '61px' }}
          justifyContent={'center'}>
          <a
            href="https://animalpolitico.com/especiales"
            target="_blank"
            rel="noreferrer noopener"
            style={{ color: 'white', textDecoration: 'none' }}>
            <Box display={'flex'} alignItems={'center'}>
              <Typography
                fontSize={{ xs: 20, md: 26 }}
                lineHeight={{ xs: '20px', md: '26px' }}
                fontFamily={'acumin-pro'}
                fontWeight={'medium'}
                marginRight={1}>
                Más reportajes
              </Typography>
              <Box
                paddingBottom={{ xs: '8px', md: '0px' }}
                paddingTop={{ xs: '0px', md: '6px' }}
                position={'relative'}
                width={{ xs: 14, md: 18 }}
                height={{ xs: 14, md: 18 }}>
                <img
                  alt="flex link"
                  src="/flecha.png"
                  width={'100%'}
                  height={'100%'}
                  style={{ objectFit: 'contain' }}
                />
              </Box>
            </Box>
          </a>
        </Box>
      </Box>
    </Box>
  );
};

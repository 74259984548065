import React from 'react';
import { Box, Button, Grid, Theme, Typography } from '@mui/material';
import { useAuth } from '../auth/auth-hook';
import { PaymentMethod } from '../../shared/types/generated';
import { CardItem } from './components/CardItem';
import { CardSection } from '../../shared/components/card/CardSection';
import { RoleAuthorization } from '../rbac/RoleAuthorization';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { RBACPermissions } from '../rbac/rbac-types';
import { SubscriptionBillingForm } from '../settings/components/SubscriptionBillingForm';

const useStyles = makeStyles<Theme>(() =>
  createStyles({
    button: {
      color: 'white!important',
      width: '200px',
      height: '38px !important',
      border: 'none !important',
    },
  }),
);
export const Card: React.FC = () => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();
  const subscription = user?.subscriptions?.length
    ? user?.subscriptions[0]
    : undefined;

  let content: JSX.Element;

  if (subscription?.payment_method === PaymentMethod.Openpay) {
    content = (
      <Box>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Typography fontWeight={'800'}>Tarjeta de pago</Typography>
            <Box>
              Elija una tarjeta de crédito y la usaremos para pagar su
              suscripción
            </Box>
          </Grid>
          <Grid item xs={12}>
            <CardItem />
          </Grid>
        </Grid>
      </Box>
    );
  } else if (subscription?.payment_method === PaymentMethod.Paypal) {
    content = (
      <Box>
        <Grid container justifyContent="center" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <Box display="flex">
              <img
                alt="icon-Card"
                src={'/paypal.svg'}
                width={20}
                height={20}
                style={{ marginRight: 10 }}
              />

              <Box>Se uso paypal para pagar su {subscription?.plan?.title}</Box>
            </Box>
          </Grid>
          <Grid item xs={12}></Grid>
        </Grid>
      </Box>
    );
  } else if (subscription?.payment_method) {
    content = <Box>Actualmente cuentas con una suscripción de cortesía.</Box>;
  } else {
    content = (
      <Box>
        <Typography variant="subtitle1">
          Actualmente no cuentas con una suscripción, te invitamos a ser parte
          de la comunidad Premium.
        </Typography>
        <Box marginTop={2}>
          <RoleAuthorization
            render={() => (
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disableElevation
                style={{
                  textTransform: 'capitalize',
                  margin: 'none !important',
                }}
                onClick={() => navigate('/planes')}
              >
                <Typography>Quiero acceso total</Typography>
              </Button>
            )}
            permission={RBACPermissions.CAN_SUBSCRIPTION}
            error={() => null}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <CardSection
          title={
            <Typography variant="h6" fontWeight={800}>
              Detalles de facturación
            </Typography>
          }
        >
          <Box p={2}>{content}</Box>
        </CardSection>
      </Grid>
      <Grid item xs={12}>
        <CardSection
          title={
            <Typography variant="h6" fontWeight={800}>
              Información de envío
            </Typography>
          }
        >
          <SubscriptionBillingForm />
        </CardSection>
      </Grid>
    </Grid>
  );
};

import React from 'react';
import { useAuth } from '../auth/auth-hook';
import { Typography } from '@mui/material';
import { CardSection } from '../../shared/components/card/CardSection';
import { TableDefault } from '../../shared/components/table/TableDefault';
import { TableColumnType } from '../../shared/components/table/table-types';
import { Invoice } from '../../shared/types/generated';
import moment from 'moment';
import { getStatusText } from './invoice-utils';

export const Invoices: React.FC = () => {
  const { user } = useAuth();

  const invoices = user?.invoices || [];
  const COLUMNS: TableColumnType<Invoice>[] = [
    {
      columnName: 'Fecha de compra',
      columnValue: (item: Invoice) =>
        moment(parseInt(item?.created_at as string)).format('DD-MM-YYYY'),
    },
    {
      columnName: 'Recurso',
      columnValue: (item: Invoice) => item?.plan?.title as string,
    },
    {
      columnName: 'Estatus',
      columnValue: (item: Invoice) => getStatusText(item?.status),
    },
    {
      columnName: 'Monto',
      columnValue: (item: Invoice) => `$${item?.plan?.price as string}`,
    },
  ];

  return (
    <CardSection
      title={
        <Typography variant="h6" fontWeight={800}>
          Facturas
        </Typography>
      }
      subheader={
        <Typography variant="subtitle1">
          Acá encontrarás información detallada del historial de pagos.
        </Typography>
      }
    >
      <TableDefault
        count={invoices.length}
        items={invoices as Invoice[]}
        columns={COLUMNS}
        page={1}
        pageSize={10}
        loading={false}
        messageLoading={'Cargando facturas...'}
        showPagination
      />
    </CardSection>
  );
};

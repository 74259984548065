import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { PageAssociated } from '../pagesAssociated';

export const Footer: React.FC = () => {
  return (
    <Box
      height={'70px'}
      width={'100%'}
      component={'div'}
      style={{
        background: '#F3F4F6 0% 0% no-repeat padding-box',
        boxShadow: ' 0px -6px 18px #00000014',
      }}
    >
      <Box paddingY={'4px'} display={'flex'} justifyContent={'center'}>
        <Typography style={{ color: '#333333' }}>
          Al suscribirte apoyas al grupo editorial Criterio
        </Typography>
      </Box>
      <Grid container justifyContent="center">
        <Grid xs={10} sm={6} md={4} item>
          <PageAssociated />
        </Grid>
      </Grid>
    </Box>
  );
};
